import backendVerifiedUser from "../../api/backendVerifiedUser";
import {
  CLOSE_LOADER,
  SET_ERROR,
  SET_LOADER,
  SET_SUCCESS,
} from "../Types/AuthTypes";
import { SET_IMAGES } from "../Types/ImagesTypes";

export const getDisplayImages = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER });
    try {
      const response = await backendVerifiedUser.get("/get-display-images");
      dispatch({ type: CLOSE_LOADER });

      // Dispatch images data specifically
      dispatch({
        type: "SET_IMAGES",
        payload: response.data.imagesData, // Now correctly accessing the imagesData field
      });

      dispatch({
        type: SET_SUCCESS,
        payload: response.data.msg,
      });

      return { success: true, payload: response.data };
    } catch (err) {
      dispatch({ type: CLOSE_LOADER });
      dispatch({
        type: SET_ERROR,
        payload: err.response?.data?.error?.msg,
      });
      return false;
    }
  };
};

export const updateDisplayImage = (data) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER });
    try {
      const response = await backendVerifiedUser.post(
        "/upload-display-image",
        data
      );
      dispatch({ type: CLOSE_LOADER });

      dispatch({
        type: "SET_IMAGES",
        payload: response.data.imagesData, // Now correctly accessing the imagesData field
      });

      dispatch({
        type: SET_SUCCESS,
        payload: response.data.msg,
      });

      return { success: true, payload: response.data };
    } catch (err) {
      dispatch({ type: CLOSE_LOADER });
      dispatch({
        type: SET_ERROR,
        payload: err.response?.data?.error?.msg,
      });
      return false;
    }
  };
};
