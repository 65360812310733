import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";

import { Checkbox } from "primereact/checkbox";

import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { getSizeOption } from "../../store/AsyncMethods/SetupMethods";
import { getsCategory } from "../../store/AsyncMethods/ScatMethod";

export default function AddCustomerSize({ formik }) {
  const dispatch = useDispatch();
  const { sCategories } = useSelector((state) => state.ScatReducer);
  const { sizeOptions } = useSelector((state) => state.SetupReducer);
  const dropdownRef = useRef(null);

  const setCustomer = (newCustomer) => {
    formik.setValues({
      ...formik.values,
      customers: [...formik.values.customers, newCustomer],
    });
  };

  const handleAddCustomerSize = () => {
    Object.keys(formik.values.customerSize).forEach((key) => {
      const objKey = key;
      const objValue = formik.values.customerSize[key];
      let existingIndex = -1;
      formik.values.customerSizes.forEach((size, index) => {
        if (
          size.option_id === objKey &&
          size.customer_id === formik.values.customerSizeId
        ) {
          existingIndex = index;
        }
      });

      if (existingIndex === -1) {
        formik.values.customerSizes.push({
          customer_id: formik.values.customerSizeId,
          option_id: objKey,
          value: objValue,
        });
      } else {
        formik.values.customerSizes[existingIndex].value = objValue;
      }
    });
  };

  useEffect(() => {
    formik.values.customers[0] = {
      name: formik.values.name,
      id: formik.values.customerId,
    };
  }, []);

  useEffect(() => {
    if (formik.values.sCategoryId > 0) {
      dispatch(getSizeOption(formik.values.sCategoryId));
    }
  }, [formik.values.sCategoryId]);

  useEffect(() => {
    const customerId = parseInt(formik.values.customerSizeId);
    if (!isNaN(customerId)) {
      const result = formik.values.customerSizes?.reduce((acc, size) => {
        if (size.customer_id === customerId) {
          acc[size.option_id] = size.value;
        }
        return acc;
      }, {});
      formik.setFieldValue("customerSize", result);
    }
  }, [formik.values.customerSizeId]);

  return (
    <div className="mx-4">
      {/* <div>
        <Button
          label="Back"
          icon="pi pi-arrow-left"
          className="p-black-btn"
          onClick={() => navigate(-1)}
        />
      </div> */}
      <form onSubmit={formik.handleSubmit}>
        <div className="card shadow-md rounded-lg p-4 mt-4">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              Manage Dependent
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="name" className="">
                  Customer Name
                </label>
                <span className=" w-full">
                  <InputText
                    disabled
                    id="name"
                    name="name"
                    className="w-full text-lg p-primary-input"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.name && formik.errors?.name && (
                <div className="p-error">{formik.errors?.name}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="phone" className="">
                  Customer Phone
                </label>
                <span className=" w-full">
                  <InputText
                    disabled
                    id="phone"
                    name="phone"
                    className="w-full text-lg p-primary-input"
                    value={formik.values?.phone}
                    onChange={formik.handleChange}
                  />
                </span>
              </div>
              {formik.touched?.phone && formik.errors?.phone && (
                <div className="p-error">{formik.errors?.phone}</div>
              )}
            </div>
            <div className="">
              <div className="flex flex-col gap-2">
                <label htmlFor="categoryId" className="">
                  Select Customer
                </label>
                <Dropdown
                  editable
                  id="customerSizeId"
                  name="customerSizeId"
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.customerSizeId}
                  inputRef={dropdownRef}
                  onChange={formik.handleChange}
                  options={formik.values.customers}
                  optionLabel="name"
                  optionValue="id"
                  filter
                  pt={{
                    root: { className: "w-full" },
                    input: { className: "w-full p-primary-input" },
                    filterIcon: { className: "ml-1" },
                    filterInput: { className: "pl-6" },
                  }}
                />
              </div>
              <button
                className=" font-semibold ml-2 mt-2"
                type="button"
                onClick={() => {
                  const newCustomer = {
                    id: formik.values.customers.length + 1,
                    name: dropdownRef.current.value,
                  };
                  setCustomer(newCustomer);
                }}
              >
                Add Customer
              </button>
            </div>
          </div>
        </div>
        <div className="card shadow-md rounded-lg p-4 mt-4">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              Manage Size
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="">
              <div className="flex flex-col    gap-2">
                <label htmlFor={`sCategoryId`} className="">
                  Select Category
                </label>

                <Dropdown
                  id={`sCategoryId`}
                  work={`sCategoryId`}
                  className="!w-full text-lg p-primary-input"
                  value={formik.values.sCategoryId}
                  onChange={formik.handleChange}
                  options={sCategories}
                  optionLabel="name"
                  optionValue="id"
                  filter
                  pt={{
                    root: { className: "w-full" },
                    input: {
                      className: "w-full p-primary-input",
                    },
                    filterIcon: { className: "ml-2" },
                    filterInput: { className: "pl-8" },
                  }}
                />
              </div>
            </div>
          </div>
          <div className=" px-4 mt-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
            {formik.values.sCategoryId > 0 &&
              sizeOptions?.map(
                (option) =>
                  option.type === "text" &&
                  !option.parent_id && (
                    <div key={option.id} className="">
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-col gap-2">
                          <label
                            key={`${option.id}-label`}
                            htmlFor={`${"customerSize." + option.id}`}
                            className=""
                          >
                            {`${option.name} (${option.unit})`}
                          </label>
                          <div className=" flex gap-2">
                            <InputText
                              key={`${option.id}-text`}
                              type="number"
                              id={`${"customerSize." + option.id}`}
                              name={`${"customerSize." + option.id}`}
                              className="w-full text-base p-primary-input"
                              value={
                                formik.values.customerSize?.[`${option.id}`]
                              }
                              onChange={formik.handleChange}
                            />
                            {sizeOptions.map(
                              (child) =>
                                child.type === "text" &&
                                child.parent_id === option.id && (
                                  <InputText
                                    key={`${child.id}-text`}
                                    type="number"
                                    id={`${"customerSize." + child.id}`}
                                    name={`${"customerSize." + child.id}`}
                                    className="w-full text-base p-primary-input"
                                    value={
                                      formik.values.customerSize?.[
                                        `${child.id}`
                                      ]
                                    }
                                    placeholder={child.name}
                                    onChange={formik.handleChange}
                                  />
                                )
                            )}
                          </div>
                        </div>
                        <div className="flex flex-col gap-2 2xl:flex-row">
                          {sizeOptions?.map((child) => {
                            if (
                              child.type === "radio" &&
                              child.parent_id === option.id
                            ) {
                              formik.values.customerSize[`${child.id}`] =
                                formik.values.customerSize[`${child.id}`] == "1"
                                  ? true
                                  : false;

                              return (
                                <div key={child.id} className="flex gap-2">
                                  <Checkbox
                                    key={`${child.id}-checkbox`}
                                    id={`${"customerSize." + child.id}`}
                                    name={`${"customerSize." + child.id}`}
                                    value={
                                      formik.values.customerSize?.[
                                        `${child.id}`
                                      ]
                                    }
                                    onChange={formik.handleChange}
                                    checked={
                                      formik.values.customerSize?.[
                                        `${child.id}`
                                      ]
                                    }
                                  />
                                  <label
                                    key={`${child.id}-label`}
                                    htmlFor={`${"customerSize." + child.id}`}
                                    className=""
                                  >
                                    {`${child.name}`}
                                  </label>
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>

          <div className=" px-4 mt-8 flex flex-col gap-2">
            {formik.values.sCategoryId > 0 &&
              sizeOptions?.map((option) => {
                if (option.type === "option" && !option.parent_id) {
                  formik.values.customerSize[`${option.id}`] =
                    formik.values.customerSize[`${option.id}`] == "1"
                      ? true
                      : false;
                  return (
                    <div key={option.id} className=" flex items-center gap-2">
                      <Checkbox
                        key={`${option.id}-checkbox`}
                        id={`${"customerSize." + option.id}`}
                        name={`${"customerSize." + option.id}`}
                        value={formik.values.customerSize?.[`${option.id}`]}
                        onChange={formik.handleChange}
                        checked={formik.values.customerSize?.[`${option.id}`]}
                      />
                      <label
                        key={`${option.id}-label`}
                        htmlFor={`${"customerSize." + option.id}`}
                        className=""
                      >
                        {`${option.name} `}
                      </label>
                    </div>
                  );
                }
              })}
          </div>
        </div>
        <div className="mt-16">
          <div className="flex justify-end gap-4">
            <Button
              label="Cancel"
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              onClick={() => {
                formik.resetForm();
              }}
            />
            <Button
              label={"Add Size"}
              type="button"
              onClick={handleAddCustomerSize}
              icon="pi pi-check"
              className="p-secondary-btn"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
