import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDisplayImages } from "../../store/AsyncMethods/ImageMethod";
import { ImageCard } from "./ImageCard";
import { Link } from "react-router-dom";

export const ImageMain = () => {
  const dispatch = useDispatch();
  const { images } = useSelector((state) => state.ImageReducer);

  useEffect(() => {
    dispatch(getDisplayImages());
  }, [dispatch]);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Manage Images</h1>
      </div>

      {/* Sections */}
      <div className="space-y-8">
        {/* Section 1 */}
        <div>
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Our Picks For You (Section 1)
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {images
              ?.filter((img) => img.section === 1)
              .map((card) => (
                <ImageCard key={card.card_id} card={card} />
              ))}
          </div>
        </div>

        {/* Section 2 */}
        <div>
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Special Offers (Section 2)
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {images
              ?.filter((img) => img.section === 2)
              .map((card) => (
                <ImageCard key={card.card_id} card={card} />
              ))}
          </div>
        </div>
        <div>
          <h2 className="text-xl font-semibold text-gray-700 mb-4">
            Featured (Section 3)
          </h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {images
              ?.filter((img) => img.section === 3)
              .map((card) => (
                <ImageCard key={card.card_id} card={card} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
