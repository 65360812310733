import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Dropdown } from "primereact/dropdown";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { getWork } from "../../store/AsyncMethods/SetupMethods";
import {
  addVendorWork,
  getVendorWork,
  getVendors,
} from "../../store/AsyncMethods/VendorMethod";
import { useNavigate } from "react-router-dom";
import { getsCategory } from "../../store/AsyncMethods/ScatMethod";
export default function VendorWork({ id }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allWork } = useSelector((state) => state.SetupReducer);
  const { vendorWork } = useSelector((state) => state.VendorReducer);
  const { sCategories } = useSelector((state) => state.ScatReducer);

  useEffect(() => {
    if (vendorWork[0]?.work) {
      formik.setValues({
        workAssignment: vendorWork,
        id: id || "",
      });
    }
  }, [vendorWork]);

  const formik = useFormik({
    initialValues: {
      workAssignment: [{ work: "", cat_id: "", price: 0 }],
      id: id || "",
    },
    validationSchema: Yup.object({
      workAssignment: Yup.array().of(
        Yup.object().shape({
          work: Yup.string().required("Work Required"),
          price: Yup.string().required("Price Required"),
        })
      ),
    }),

    onSubmit: async (data) => {
      dispatch(addVendorWork(data)).then((success) => {
        if (success) {
          formik.resetForm();
          dispatch(getVendors());

          navigate("/manage-vendor");
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getsCategory());

    dispatch(getWork());
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(getVendorWork(id));
    }
  }, []);

  return (
    <div className="mx-4">
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="card shadow-md rounded-lg p-4 mt-3">
            <Divider>
              <span className="text-2xl font-bold text-center text-primary mx-1">
                Work Assignment
              </span>
            </Divider>

            <div className="">
              <div>
                {formik.values.workAssignment.length > 0 &&
                  formik.values.workAssignment.map((work, index) => (
                    <div
                      key={index}
                      className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                    >
                      
                      <div className="">
                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor={`workAssignment.${index}.cat_id`}
                            className=""
                          >
                            Select Category
                          </label>

                          <Dropdown
                            id={`workAssignment.${index}.cat_id`}
                            work={`workAssignment.${index}.cat_id`}
                            className="!w-full text-lg p-primary-input"
                            value={formik.values.workAssignment[index].cat_id}
                            onChange={formik.handleChange}
                            options={sCategories}
                            optionLabel="name"
                            optionValue="id"
                            filter
                            pt={{
                              root: { className: "w-full" },
                              input: {
                                className: "w-full p-primary-input",
                              },
                              filterIcon: { className: "ml-2" },
                              filterInput: { className: "pl-8" },
                            }}
                          />
                        </div>
                        {formik.touched?.[`workAssignment[${index}].cat_id`] &&
                          formik.errors?.[
                            `workAssignment[${index}].cat_id`
                          ] && (
                            <div className="p-error">
                              {
                                formik.errors?.[
                                  `workAssignment[${index}].cat_id`
                                ]
                              }
                            </div>
                          )}
                      </div>
                      <div className="">
                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor={`workAssignment.${index}.work`}
                            className=""
                          >
                            Work Type
                          </label>

                          <Dropdown
                            id={`workAssignment.${index}.work`}
                            work={`workAssignment.${index}.work`}
                            className="!w-full text-lg p-primary-input"
                            value={formik.values.workAssignment[index].work}
                            onChange={formik.handleChange}
                            options={allWork}
                            optionLabel="type"
                            optionValue="id"
                            filter
                            pt={{
                              root: { className: "w-full" },
                              input: {
                                className: "w-full p-primary-input",
                              },
                              filterIcon: { className: "ml-2" },
                              filterInput: { className: "pl-8" },
                            }}
                          />
                        </div>
                        {formik.touched?.[`workAssignment[${index}].work`] &&
                          formik.errors?.[`workAssignment[${index}].work`] && (
                            <div className="p-error">
                              {formik.errors?.[`workAssignment[${index}].work`]}
                            </div>
                          )}
                      </div>
                      <div className="">
                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor={`workAssignment.${index}.price`}
                            className=""
                          >
                            Price
                          </label>
                          <span className=" flex gap-2 w-full">
                            <InputText
                              id={`workAssignment.${index}.price`}
                              work={`workAssignment.${index}.price`}
                              className="w-full text-lg p-primary-input"
                              value={formik.values.workAssignment[index].price}
                              onChange={formik.handleChange}
                            />
                          </span>
                        </div>
                        {formik.touched?.[`workAssignment[${index}].price`] &&
                          formik.errors?.[`workAssignment[${index}].price`] && (
                            <div className="p-error">
                              {
                                formik.errors?.[
                                  `workAssignment[${index}].price`
                                ]
                              }
                            </div>
                          )}
                      </div>
                      <div className=" flex justify-start items-end gap-2">
                        <span>
                          <Button
                            icon="pi pi-times"
                            className="p-red-btn"
                            rounded
                            type="button"
                            aria-label="Filter"
                            onClick={() => {
                              if (index > 0) {
                                formik.values.workAssignment.splice(index, 1);
                                formik.setFieldValue("workAssignment", [
                                  ...formik.values.workAssignment,
                                ]);
                              } else {
                                formik.resetForm();
                              }
                            }}
                          />
                        </span>
                        <span>
                          <Button
                            icon="pi pi-plus"
                            rounded
                            type="button"
                            onClick={() => {
                              formik.setFieldValue("workAssignment", [
                                ...formik.values.workAssignment,
                                { work: "", price: "0" },
                              ]);
                            }}
                            aria-label="Filter"
                          />
                        </span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="flex justify-end gap-4">
              <Button
                label="Cancel"
                icon="pi pi-times"
                className="p-red-btn"
                type="button"
                onClick={() => {
                  formik.resetForm();
                }}
              />
              <Button
                label="Submit"
                type="submit"
                icon="pi pi-check"
                className="p-secondary-btn"
              />
            </div>
          </div>
        </form>
      </>
    </div>
  );
}
