import { SETS_S_CATEGORIES } from "../Types/ScatTypes";

const initialState = {
  sCategories: [],
};

const ScatReducer = (state = initialState, action) => {
  if (action.type === SETS_S_CATEGORIES) {
    return { ...state, sCategories: action.payLoad };
  } else {
    return state;
  }
};

export default ScatReducer;
