export const SET_LOADER = "SET_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";
export const SET_TOKEN = "SET_TOKEN";
export const LOGOUT = "LOGOUT";
export const SET_VALIDATE_ERROR = "SET_VALIDATE_ERROR";
export const RESET_VALIDATE_ERROR = "RESET_VALIDATE_ERROR";
