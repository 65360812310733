import {
  SET_SIZE_OPTION,
  SET_WORK_ASSIGNMENT,
  SET_CUSTOMIZATION_PRODUCT,
} from "../Types/SetupTypes";
const initialState = {
  allWork: [],
  allCustomizationProduct: [],
  sizeOptions: [],
};

const SetupReducer = (state = initialState, action) => {
  if (action.type === SET_WORK_ASSIGNMENT) {
    return { ...state, allWork: action.payLoad };
  } else if (action.type === SET_SIZE_OPTION) {
    return { ...state, sizeOptions: action.payLoad };
  } else if (action.type === SET_CUSTOMIZATION_PRODUCT) {
    console.log(action.payLoad);
    return { ...state, allCustomizationProduct: action.payLoad };
  } else {
    return state;
  }
};

export default SetupReducer;
