import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { addWork, getWork } from "../../store/AsyncMethods/SetupMethods";
export default function AddWorkAssignment() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { allWork } = useSelector((state) => state.SetupReducer);

  useEffect(() => {
    if (allWork[0]) {
      const workArr = [...new Set(allWork.map((work) => work.type))];

      formik.setValues({ workAssignment: workArr });
    }
  }, [allWork]);
  const formik = useFormik({
    initialValues: {
      workAssignment: [""],
    },
    onSubmit: async (data) => {
      dispatch(addWork(data)).then((success) => {
        if (success) {
          dispatch(getWork());
        }
      });
    },
  });
  useEffect(() => {
    dispatch(getWork());
  }, []);
  return (
    <div className="mx-4">
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="card shadow-md rounded-lg p-4 mt-3">
            <Divider>
              <span className="text-2xl font-bold text-center text-primary mx-1">
                Add Work Assignment
              </span>
            </Divider>

            <div className="">
              <div>
                {formik.values.workAssignment.length > 0 &&
                  formik.values.workAssignment.map((work, index) => (
                    <div
                      key={index}
                      className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                    >
                      <div className="">
                        <div className="flex flex-col gap-2">
                          <label
                            htmlFor={`workAssignment.${index}`}
                            className=""
                          >
                            Work Name
                          </label>
                          <span className=" flex gap-2 w-full">
                            <InputText
                              id={`workAssignment.${index}`}
                              work={`workAssignment.${index}`}
                              className="w-full text-lg p-primary-input"
                              value={formik.values.workAssignment[index]}
                              onChange={formik.handleChange}
                            />
                          </span>
                        </div>
                        {formik.touched?.[`workAssignment[${index}]`] &&
                          formik.errors?.[`workAssignment[${index}]`] && (
                            <div className="p-error">
                              {formik.errors?.[`workAssignment[${index}]`]}
                            </div>
                          )}
                      </div>
                      <div className=" flex justify-start items-end gap-2">
                        <span>
                          <Button
                            icon="pi pi-times"
                            className="p-red-btn"
                            rounded
                            type="button"
                            aria-label="Filter"
                            onClick={() => {
                              if (index > 0) {
                                formik.values.workAssignment.splice(index, 1);
                                formik.setFieldValue("workAssignment", [
                                  ...formik.values.workAssignment,
                                ]);
                              }
                            }}
                          />
                        </span>
                        {index + 1 == formik?.values?.workAssignment.length && (
                          <span>
                            <Button
                              icon="pi pi-plus"
                              rounded
                              type="button"
                              onClick={() => {
                                formik.setFieldValue("workAssignment", [
                                  ...formik.values.workAssignment,
                                  "",
                                ]);
                              }}
                              aria-label="Filter"
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="mt-8">
            <div className="flex justify-end gap-4">
              <Button
                label={t("cancel")}
                icon="pi pi-times"
                className="p-red-btn"
                type="button"
                onClick={() => {
                  formik.resetForm();
                }}
              />
              <Button
                label={t("submit")}
                type="submit"
                icon="pi pi-check"
                className="p-secondary-btn"
              />
            </div>
          </div>
        </form>
      </>
    </div>
  );
}
