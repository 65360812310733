import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
const SalesRoutes = () => {
  const { user, token } = useSelector((state) => state.AuthReducer);
  return token ? (
    user?.role_id === 2 ? (
      <Outlet />
    ) : (
      <Navigate to="/dashboard" />
    )
  ) : (
    <Navigate to="/" />
  );
};

export default SalesRoutes;
