import React, { useEffect, useState } from "react";
import {
  FaFacebook,
  FaWhatsapp,
  FaInstagram,
  FaPinterest,
} from "react-icons/fa";
import { useSelector } from "react-redux";

export default function CustomizationProductInvoice() {
  const { user } = useSelector((state) => state.AuthReducer);
  const { specificSale } = useSelector((state) => state.SalesReducer);
  console.log(specificSale?.customizationProducts);
  const [currentTime, setCurrentTime] = useState("");
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const date = specificSale
        ? new Date(specificSale.created_at)
        : new Date();
      const timeString = date.toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
      setCurrentTime(timeString);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, [specificSale]);

  useEffect(() => {
    const updateDate = () => {
      const date = specificSale
        ? new Date(specificSale.created_at)
        : new Date();
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const dateString = `${day}/${month}/${year}`;
      setCurrentDate(dateString);
    };

    updateDate();
  }, [specificSale]);

  if (!specificSale) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-[80mm] mx-auto bg-white shadow-lg border border-gray-300 print:shadow-none print:border-none">
      <div className="flex justify-center py-4 border-b border-gray-300">
        <img src="/images/logo2.jpg" className="w-32" alt="Logo" />
      </div>

      <div className="text-xs leading-tight p-4 border-b border-gray-300">
        <div className="text-center font-bold">
          Rehman Shaheed Road Opp Total Petrol Pump, Gujrat
        </div>
        <div className="text-center">www.tehzeebdesigner.com | 0533-709581</div>
        <div className="text-center">Whatsapp no | 0321-7542055</div>
      </div>

      <div className="p-4 border-b border-gray-300">
        <div className="text-center text-lg font-bold">
          {specificSale.status === "refund" ? "REFUND" : "SALES"} INVOICE
        </div>
        <div className="text-center text-sm font-bold">
          Receipt #{String(specificSale.id).padStart(5, "0")}
        </div>
      </div>

      <div className="p-4 border-b border-gray-300">
        <div className="text-center text-sm font-bold">
          {specificSale.customer_name || ""}
        </div>
        <div className="text-center text-sm">
          {specificSale.customer_phone || ""}
        </div>
      </div>

      <div className="flex justify-between text-xs font-bold px-4 py-2 border-b border-gray-300">
        <span>{user.first_name}</span>
        <span>{currentDate}</span>
        <span>{currentTime}</span>
      </div>

      <div className="p-4">
        <div className="text-sm font-bold mb-2">Customization Products</div>
        <div className="border border-gray-300 rounded">
          {specificSale.customizationProducts &&
          specificSale.customizationProducts.length > 0 ? (
            specificSale.customizationProducts.map((item, index) => (
              <div
                key={item.customization_product_id}
                className={`p-2 text-xs ${
                  index !== specificSale.customizationProducts.length - 1
                    ? "border-b border-gray-300"
                    : ""
                }`}
              >
                {item.customization_product_value}
              </div>
            ))
          ) : (
            <div className="p-2 text-xs">
              No customization products available
            </div>
          )}
        </div>
      </div>

      <div className="text-center p-4 border-t border-gray-300">
        <div className="text-xs mb-2">
          Note: After delivery, complains for fabric or stitching will not be
          entertained after 3 working days
        </div>
        <div className="text-lg font-bold mb-2">THANK YOU!</div>
        <div className="text-sm">Follow us: Tehzeeb Designer</div>
        <div className="flex justify-center gap-4 text-xl mt-4">
          <FaWhatsapp />
          <FaFacebook />
          <FaInstagram />
          <FaPinterest />
        </div>
        <div className="text-xs mt-4 font-bold">
          Software provided by Reys Solutions
        </div>
        <div className="text-xs">Phone: +92 322 5991588</div>
      </div>
    </div>
  );
}
