import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateDisplayImage } from "../../store/AsyncMethods/ImageMethod";

function ImageEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { images } = useSelector((state) => state.ImageReducer);

  const [formData, setFormData] = useState({
    title: "",
    image: null,
    imagePreview: "",
    sub_title: "", // Included only to manage non-section 3 logic
  });

  const [isSection3, setIsSection3] = useState(false);
  const [initialData, setInitialData] = useState({});
  const [isChanged, setIsChanged] = useState(false); // Track changes

  useEffect(() => {
    if (images) {
      const currentImage = images.find((img) => img.card_id === parseInt(id));
      if (currentImage) {
        setIsSection3(currentImage.section === 3); // Check if the card belongs to section 3
        const initialFormData = {
          title: currentImage.title,
          sub_title: currentImage.section === 3 ? "" : currentImage.sub_title,
          imagePreview: `https://tehzeeb.ezmartstore.com/display_images/${currentImage.image}`,
        };
        setFormData(initialFormData);
        setInitialData(initialFormData); // Store the initial form data for change detection
      }
    }
  }, [id, images]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsChanged(true); // Mark as changed
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({
        ...prev,
        image: file,
        imagePreview: URL.createObjectURL(file),
      }));
      setIsChanged(true); // Mark as changed
    }
  };

  useEffect(() => {
    // Check if any field is changed
    const hasChanges =
      formData.title !== initialData.title ||
      (!isSection3 && formData.sub_title !== initialData.sub_title) ||
      formData.image !== null; // Image field is checked separately

    setIsChanged(hasChanges);
  }, [formData, initialData, isSection3]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const submitData = new FormData();
    submitData.append("cardId", id);
    submitData.append("title", formData.title);

    if (!isSection3) {
      submitData.append("subTitle", formData.sub_title);
    } else {
      submitData.append("subTitle", "");
    }

    if (formData.image) {
      submitData.append("image", formData.image);
    }

    try {
      await dispatch(updateDisplayImage(submitData));
      navigate("/ecom-images");
    } catch (error) {
      console.error("Error updating image:", error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-800">Edit Image Card</h1>
        <p className="text-gray-600">Update the details for this image card</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            {/* Show subtitle field only if not section 3 */}
            {!isSection3 && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Subtitle
                </label>
                <input
                  type="text"
                  name="sub_title"
                  value={formData.sub_title}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            )}

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Image
              </label>
              <input
                type="file"
                name="image"
                accept="image/*"
                onChange={handleImageChange}
                className="w-full"
              />
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-sm font-medium text-gray-700 mb-2">
              Image Preview
            </p>
            {formData.imagePreview && (
              <img
                src={formData.imagePreview}
                alt="Preview"
                className="w-full h-64 object-cover rounded-lg"
              />
            )}
          </div>
        </div>

        <div className="flex gap-4">
          <button
            type="submit"
            className="bg-blue-600 text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-colors"
            disabled={!isChanged} // Disable if no changes
          >
            Save Changes
          </button>
          <button
            type="button"
            onClick={() => navigate("/ecom-images")}
            className="bg-gray-200 text-gray-800 px-6 py-2 rounded-md hover:bg-gray-300 transition-colors"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}

export default ImageEdit;
