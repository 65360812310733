import {
  RESET_SALES,
  RESET_SPECIFIC_SALES,
  SET_SALES,
  SET_SPECIFIC_SALES,
} from "../Types/SalesTypes";

const initialState = {
  allSales: [],
  specificSale: null,
};

const SalesReducer = (state = initialState, action) => {
  if (action.type === SET_SALES) {
    return { ...state, allSales: action.payLoad };
  } else if (action.type === RESET_SALES) {
    return { ...state, allSales: [] };
  } else if (action.type === SET_SPECIFIC_SALES) {
    return { ...state, specificSale: action.payLoad };
  } else if (action.type === RESET_SPECIFIC_SALES) {
    return { ...state, specificSale: null };
  } else {
    return state;
  }
};

export default SalesReducer;
