import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { getSizeOption } from "../../store/AsyncMethods/SetupMethods";
import { getsCategory } from "../../store/AsyncMethods/ScatMethod";
import {
  addCustomerSize,
  addCustomers,
  getCustomerSize,
  getCustomers,
} from "../../store/AsyncMethods/CustomerMethod";
import { useFormik } from "formik";

export default function AddCustomerSize() {
  const dispatch = useDispatch();
  const { sCategories } = useSelector((state) => state.ScatReducer);
  const { sizeOptions } = useSelector((state) => state.SetupReducer);
  const { customerSize, allCustomers } = useSelector(
    (state) => state.CustomersReducer
  );

  const dropdownRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      customerSizeId: "",
      customerSize: {},
      sCategoryId: 0,
      name: "",
      phone: "",
    },
    onSubmit: async (values) => {
      dispatch(addCustomerSize(values))?.then((success) => {
        if (success) {
          formik.resetForm();
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getCustomers());
    dispatch(getsCategory());
  }, []);

  useEffect(() => {
    if (formik.values.sCategoryId > 0) {
      dispatch(getSizeOption(formik.values.sCategoryId));
    }
  }, [formik.values.sCategoryId]);

  useEffect(() => {
    const customerId = parseInt(formik.values.customerSizeId);
    if (!isNaN(customerId)) {
      dispatch(getCustomerSize(customerId));
    }
  }, [formik.values.customerSizeId]);
  useEffect(() => {
    const customerId = parseInt(formik.values.customerSizeId);
    if (!isNaN(customerId)) {
      const result = customerSize?.customerSize.reduce((acc, size) => {
        if (size.customer_id === customerId) {
          acc[size.option_id] = size.value;
        }
        return acc;
      }, {});
      formik.setFieldValue("customerSize", result);
    }
    formik.setFieldValue("name", customerSize?.name);
    formik.setFieldValue("phone", customerSize?.phone);
  }, [customerSize]);

  return (
    <div className="mx-4">
      <form onSubmit={formik.handleSubmit} className="space-y-6">
        <div className="card shadow-md rounded-lg p-4 mt-4">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              Manage Customer
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="space-y-2">
              <label htmlFor="name" className="text-sm font-medium">
                Customer Name
              </label>
              <InputText
                id="name"
                name="name"
                className="w-full text-lg p-primary-input"
                value={formik?.values?.name}
                onChange={formik.handleChange}
              />
              {formik.touched?.name && formik.errors?.name && (
                <div className="p-error text-sm">{formik.errors?.name}</div>
              )}
            </div>
            <div className="space-y-2">
              <label htmlFor="phone" className="text-sm font-medium">
                Customer Phone
              </label>
              <InputText
                id="phone"
                name="phone"
                className="w-full text-lg p-primary-input"
                value={formik?.values?.phone}
                onChange={formik.handleChange}
              />
              {formik.touched?.phone && formik.errors?.phone && (
                <div className="p-error text-sm">{formik.errors?.phone}</div>
              )}
            </div>
            <div className="space-y-2">
              <label htmlFor="customerSizeId" className="text-sm font-medium">
                Select Customer
              </label>
              <Dropdown
                id="customerSizeId"
                name="customerSizeId"
                className="w-full text-lg p-primary-input"
                value={formik.values.customerSizeId}
                inputRef={dropdownRef}
                onChange={formik.handleChange}
                options={allCustomers}
                optionLabel={(option) => `${option.name} - ${option.phone}`}
                optionValue="id"
                filter
                pt={{
                  root: { className: "w-full" },
                  input: { className: "w-full p-primary-input" },
                  filterIcon: { className: "ml-1" },
                  filterInput: { className: "pl-6" },
                }}
              />
            </div>
          </div>

          <div className="mt-4 px-4 flex justify-end">
            <button
              className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark transition-colors duration-200 shadow-md flex items-center space-x-2 text-sm font-semibold"
              type="button"
              onClick={async () => {
                dispatch(
                  addCustomers({
                    phone: formik.values.phone,
                    name: formik.values.name,
                  })
                );
                dispatch(getCustomers());
              }}
            >
              <i className="pi pi-user-plus"></i>
              <span>Add Customer</span>
            </button>
          </div>
        </div>

        <div className="card shadow-md rounded-lg p-4 mt-4">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              Manage Size
            </span>
          </Divider>

          <div className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <div className="col-span-full">
              <label
                htmlFor="sCategoryId"
                className="text-sm font-medium block mb-2"
              >
                Select Category
              </label>
              <Dropdown
                id="sCategoryId"
                name="sCategoryId"
                className="w-full md:w-1/2 lg:w-1/3 text-lg p-primary-input"
                value={formik.values.sCategoryId}
                onChange={formik.handleChange}
                options={sCategories}
                optionLabel="name"
                optionValue="id"
                filter
                pt={{
                  root: { className: "w-full" },
                  input: { className: "w-full p-primary-input" },
                  filterIcon: { className: "ml-2" },
                  filterInput: { className: "pl-8" },
                }}
              />
            </div>
          </div>

          <div className="px-4 mt-6 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5">
            {formik.values.sCategoryId > 0 &&
              sizeOptions?.map((option) =>
                option.type === "text" && !option.parent_id ? (
                  <div key={option.id} className="space-y-2">
                    <label
                      htmlFor={`customerSize.${option.id}`}
                      className="text-sm font-medium block"
                    >
                      {`${option.name} (${option.unit})`}
                    </label>
                    <div className="flex gap-2">
                      <InputText
                        type="number"
                        id={`customerSize.${option.id}`}
                        name={`customerSize.${option.id}`}
                        className="w-full text-base p-primary-input"
                        value={formik.values.customerSize?.[option.id] || ""}
                        onChange={formik.handleChange}
                      />
                      {sizeOptions
                        .filter(
                          (child) =>
                            child.type === "text" &&
                            child.parent_id === option.id
                        )
                        .map((child) => (
                          <InputText
                            key={`${child.id}-text`}
                            type="number"
                            id={`customerSize.${child.id}`}
                            name={`customerSize.${child.id}`}
                            className="w-full text-base p-primary-input"
                            value={formik.values.customerSize?.[child.id] || ""}
                            placeholder={child.name}
                            onChange={formik.handleChange}
                          />
                        ))}
                    </div>
                    <div className="flex flex-col gap-2 2xl:flex-row">
                      {sizeOptions
                        .filter(
                          (child) =>
                            child.type === "radio" &&
                            child.parent_id === option.id
                        )
                        .map((child) => (
                          <div
                            key={child.id}
                            className="flex items-center gap-2"
                          >
                            <RadioButton
                              id={`customerSize.${child.id}`}
                              name={`customerSize.${option.id}`} // Grouping name
                              value={child.id}
                              onChange={formik.handleChange}
                              checked={
                                formik.values.customerSize?.[option.id] ===
                                child.id
                              }
                            />
                            <label
                              htmlFor={`customerSize.${child.id}`}
                              className="text-sm"
                            >
                              {child.name}
                            </label>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null
              )}
          </div>

          <div className="px-4 mt-8 flex flex-col gap-2">
            {formik.values.sCategoryId > 0 &&
              sizeOptions?.map((option) => {
                if (option.type === "option" && !option.parent_id) {
                  return (
                    <div key={option.id} className="flex items-center gap-2">
                      <RadioButton
                        id={`customerSize.${option.id}`}
                        name={`customerSize.${option.id}`} // Unique name for each group
                        value={option.id}
                        onChange={formik.handleChange}
                        checked={
                          formik.values.customerSize?.[option.id] === option.id
                        }
                      />
                      <label
                        htmlFor={`customerSize.${option.id}`}
                        className="text-sm"
                      >
                        {option.name}
                      </label>
                    </div>
                  );
                }
                return null;
              })}
          </div>
        </div>

        <div className="mt-16">
          <div className="flex justify-end gap-4">
            <Button
              label="Clear"
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              onClick={() => formik.resetForm()}
            />
            <Button
              label="Add Size"
              type="submit"
              icon="pi pi-check"
              className="p-secondary-btn"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
