import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import {
  addCustomizationProduct,
  getCustomizationProduct,
} from "../../store/AsyncMethods/SetupMethods";
import { InputNumber } from "primereact/inputnumber";

export default function AddCustomizationProduct() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { allCustomizationProduct } = useSelector(
    (state) => state.SetupReducer
  );

  useEffect(() => {
    if (allCustomizationProduct[0]) {
      const customizationProducts = allCustomizationProduct.map((product) => ({
        type: product.type,
        price: product.price,
      }));

      formik.setValues({ customizationProducts });
    }
  }, [allCustomizationProduct]);

  const formik = useFormik({
    initialValues: {
      customizationProducts: [{ type: "", price: 0 }],
    },
    validationSchema: Yup.object({
      customizationProducts: Yup.array().of(
        Yup.object().shape({
          type: Yup.string().required("Work Name Required"),
          price: Yup.number().required("Price Required"),
        })
      ),
    }),
    onSubmit: async (data) => {
      dispatch(addCustomizationProduct(data)).then((success) => {
        if (success) {
          dispatch(getCustomizationProduct());
        }
      });
    },
  });

  useEffect(() => {
    dispatch(getCustomizationProduct());
  }, []);

  return (
    <div className="mx-4">
      <form onSubmit={formik.handleSubmit}>
        <div className="card shadow-md rounded-lg p-4 mt-3">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              Add Customization Product
            </span>
          </Divider>

          <div className="">
            <div>
              {formik.values.customizationProducts.map((product, index) => (
                <div
                  key={index}
                  className="px-4 gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                >
                  <div className="">
                    <div className="flex flex-col gap-2">
                      <label
                        htmlFor={`customizationProducts.${index}.type`}
                        className=""
                      >
                        Work Name
                      </label>
                      <span className="flex gap-2 w-full">
                        <InputText
                          id={`customizationProducts.${index}.type`}
                          name={`customizationProducts.${index}.type`}
                          className="w-full text-lg p-primary-input"
                          value={product.type}
                          onChange={formik.handleChange}
                        />
                      </span>
                    </div>
                    {formik.touched?.customizationProducts?.[index]?.type &&
                      formik.errors?.customizationProducts?.[index]?.type && (
                        <div className="p-error">
                          {formik.errors.customizationProducts[index].type}
                        </div>
                      )}
                  </div>
                  <div className="">
                    <div className="flex flex-col gap-2">
                      <label
                        htmlFor={`customizationProducts.${index}.price`}
                        className=""
                      >
                        Price
                      </label>
                      <span className="flex gap-2 w-full">
                        <InputNumber
                          id={`customizationProducts.${index}.price`}
                          name={`customizationProducts.${index}.price`}
                          className="w-full text-lg p-primary-input"
                          value={product.price}
                          onValueChange={(e) =>
                            formik.setFieldValue(
                              `customizationProducts.${index}.price`,
                              e.value
                            )
                          }
                        />
                      </span>
                    </div>
                    {formik.touched.customizationProducts?.[index]?.price &&
                      formik.errors.customizationProducts?.[index]?.price && (
                        <div className="p-error">
                          {formik.errors.customizationProducts[index].price}
                        </div>
                      )}
                  </div>
                  <div className="flex justify-start items-end gap-2">
                    <span>
                      <Button
                        icon="pi pi-times"
                        className="p-red-btn"
                        rounded
                        type="button"
                        aria-label="Remove"
                        onClick={() => {
                          if (index > 0) {
                            const updatedProducts = [
                              ...formik.values.customizationProducts,
                            ];
                            updatedProducts.splice(index, 1);
                            formik.setFieldValue(
                              "customizationProducts",
                              updatedProducts
                            );
                          }
                        }}
                      />
                    </span>
                    {index + 1 ===
                      formik.values.customizationProducts.length && (
                      <span>
                        <Button
                          icon="pi pi-plus"
                          rounded
                          type="button"
                          onClick={() => {
                            formik.setFieldValue("customizationProducts", [
                              ...formik.values.customizationProducts,
                              { type: "" },
                            ]);
                          }}
                          aria-label="Add"
                        />
                      </span>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-8">
          <div className="flex justify-end gap-4">
            <Button
              label={t("cancel")}
              icon="pi pi-times"
              className="p-red-btn"
              type="button"
              onClick={() => {
                formik.resetForm();
              }}
            />
            <Button
              label={t("submit")}
              type="submit"
              icon="pi pi-check"
              className="p-secondary-btn"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
