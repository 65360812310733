import React from "react";
import { Edit } from "lucide-react";
import { Link } from "react-router-dom";

export const ImageCard = ({ card }) => {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
      <div className="relative aspect-square">
        <img
          src={`https://tehzeeb.ezmartstore.com/display_images/${card.image}`}
          alt={card.title}
          className="w-full h-full object-cover"
        />
        <Link
          to={`/ecom-images/edit/${card.card_id}`}
          className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100"
        >
          <Edit className="w-4 h-4 text-gray-600" />
        </Link>
      </div>
      <div className="p-4">
        <h3 className="font-semibold text-gray-800 truncate">{card.title}</h3>
        <p className="text-sm text-gray-600 truncate">{card.sub_title}</p>
        <p className="text-xs text-gray-500 mt-1">Section: {card.section}</p>
      </div>
    </div>
  );
};
