import { Divider } from "primereact/divider";
import React, { useEffect } from "react";

export default function SalesCalculation({ formik }) {
  const safeCalc = (calc, defaultValue = 0) => {
    try {
      const result = calc();
      return isNaN(result) ? defaultValue : result;
    } catch (error) {
      console.error("Error in calculation:", error);
      return defaultValue;
    }
  };

  const calculateTotalSalePrice = () =>
    safeCalc(() =>
      formik.values.products.reduce(
        (acc, item) =>
          acc +
          (Number(item.purchasePrice) || 0) * (Number(item.quantity) || 0),
        0
      )
    );

  const calculateTotalPurchasePrice = () =>
    safeCalc(() =>
      formik.values.products.reduce(
        (acc, item) =>
          acc +
          (Number(item.purchasePrice) || 0) * (Number(item.quantity) || 0),
        0
      )
    );

  const calculateTotalDiscount = () =>
    safeCalc(() =>
      formik.values.products.reduce(
        (acc, item) => acc + (Number(item.discount) || 0),
        0
      )
    );

  const calculateTotalSaleDiscount = () =>
    safeCalc(() =>
      formik.values.products.reduce(
        (acc, item) =>
          acc + (Number(item.saleDiscount) || 0) * (Number(item.quantity) || 0),
        0
      )
    );

  useEffect(() => {
    if (!formik.values.products) {
      console.warn("Products array is undefined or null");
      return;
    }

    const totalSalePrice = calculateTotalSalePrice();
    const totalPurchasePrice = calculateTotalPurchasePrice();
    const totalDiscount = calculateTotalDiscount();
    const totalSaleDiscount = calculateTotalSaleDiscount();
    const vatAmount = safeCalc(
      () => totalSalePrice * (Number(formik.values.vatPer) / 100)
    );

    const isRefund = formik.values.saleStatus === "refund";

    const totalAmount = safeCalc(() =>
      isRefund
        ? (totalPurchasePrice - totalDiscount - vatAmount - totalSaleDiscount) *
          -1
        : totalPurchasePrice - totalDiscount - totalSaleDiscount + vatAmount
    );

    formik.setFieldValue("totalPurchase", totalPurchasePrice);
    formik.setFieldValue(
      "totalSales",
      isRefund ? -totalPurchasePrice : totalPurchasePrice
    );
    formik.setFieldValue(
      "totalDiscount",
      isRefund ? -totalDiscount : totalDiscount
    );
    formik.setFieldValue("vatAmount", vatAmount);
    formik.setFieldValue("totalSaleDiscount", totalSaleDiscount);
    formik.setFieldValue("totalAmount", totalAmount);
  }, [formik.values]);

  return (
    <div className="flex justify-end">
      <div className="w-full lg:w-7/12 xl:w-5/12">
        <div className="card px-4 pt-0 pb-4 shadow-md rounded-lg flex flex-col gap-2">
          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              {formik.values?.saleStatus === "invoice"
                ? "Sales Summary"
                : "Refund Summary"}
            </span>
          </Divider>
          <div className="flex justify-between">
            <h1 className="text-lg font-semibold">Total Products</h1>
            <span className="text-lg">{formik?.values?.products?.length}</span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">Sale Discount (Total)</h1>
            <span className="text-lg">
              RS {formik.values?.totalSaleDiscount.toLocaleString("en-IN")}
            </span>
          </div>
          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">Discount (Total)</h1>
            <span className="text-lg">
              RS {formik.values?.totalDiscount.toLocaleString("en-IN")}
            </span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">
              Total{" "}
              {formik.values?.saleStatus === "invoice" ? "Sales" : "Refund"}{" "}
              Price (Without discount)
            </h1>
            <span className="text-lg">
              RS {formik.values?.totalSales.toLocaleString("en-IN")}
            </span>
          </div>

          {/* <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">VAT %</h1>
            <span className="text-lg">{formik.values?.vatPer}%</span>
          </div>

          <div className="flex justify-between gap-2">
            <h1 className="text-lg font-semibold">VAT Amount</h1>
            <span className="text-lg">
              RS {formik.values?.vatAmount.toLocaleString("en-IN")}
            </span>
          </div> */}

          <Divider pt={{ root: { className: "!my-2" } }} />

          <div className="flex justify-end gap-3">
            <h1 className="text-lg font-semibold">Total Amount:</h1>
            <span className="text-lg">
              RS {formik.values?.totalAmount.toLocaleString("en-IN")}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
