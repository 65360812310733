import React, { useEffect } from "react";
import { Divider } from "primereact/divider";

export default function CustomizationProduct({ formik }) {
  useEffect(() => {
    if (formik.values.customizationProducts.length > 0) {
      const customizationProducts = [...formik.values.customizationProducts];
      console.log(formik.values.customizationProductsTotal);
    }
  }, [formik.values.customizationProducts]);

  const formatDate = (dateString) => {
    if (!dateString) return "Not set";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <div className="mx-4">
      <form onSubmit={formik.handleSubmit}>
        <div className="card shadow-md rounded-lg p-6 mt-4 bg-white">
          {/* Date Information */}
          <div className="flex justify-between mb-4">
            <div>
              <span className="font-bold">Trial Date:</span>{" "}
              {formatDate(formik.values.trialDate)}
            </div>
            <div>
              <span className="font-bold">Delivery Date:</span>{" "}
              {formatDate(formik.values.deliveryDate)}
            </div>
          </div>

          <Divider>
            <span className="text-2xl font-bold text-center text-primary mx-1">
              Customization Products
            </span>
          </Divider>
          <div className="mt-6">
            {formik.values.customizationProducts?.length > 0 ? (
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-300">
                  <thead>
                    <tr className="bg-gray-100">
                      <th className="py-2 px-4 border-b text-left">#</th>
                      <th className="py-2 px-4 border-b text-left">Type</th>
                      <th className="py-2 px-4 border-b text-left">Quantity</th>
                      <th className="py-2 px-4 border-b text-left">Price</th>
                      <th className="py-2 px-4 border-b text-left">Discount</th>
                      <th className="py-2 px-4 border-b text-left">Subtotal</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.customizationProducts.map((item, index) => (
                      <tr key={index} className="hover:bg-gray-50">
                        <td className="py-2 px-4 border-b">{index + 1}</td>
                        <td className="py-2 px-4 border-b">
                          {item.customization_product_value}
                        </td>
                        <td className="py-2 px-4 border-b">{item.quantity}</td>
                        <td className="py-2 px-4 border-b">
                          ${item.price.toFixed(2)}
                        </td>
                        <td className="py-2 px-4 border-b">
                          Rs {item.discount}
                        </td>
                        <td className="py-2 px-4 border-b">
                          Rs
                          {(item.quantity * item.price - item.discount).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="bg-gray-100 font-bold">
                      <td colSpan="5" className="py-2 px-4 border-b text-right">
                        Total:
                      </td>
                      <td className="py-2 px-4 border-b">
                        ${formik.values.customizationProductsTotal.toFixed(2)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            ) : (
              <div className="text-center text-gray-500 mt-4">
                No customization products available.
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}
