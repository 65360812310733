import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import { useEffect } from "react";

export default function AddPurchaseForm({ formik }) {
  const { purchaseId } = useParams();
  const statusOptions = [
    { name: "Approved", value: "approved" },
    { name: "Pending", value: "pending" },
  ];

  return (
    <div className="card shadow-md rounded-lg p-4 mt-1">
      <Divider>
        <span className="text-2xl font-bold text-center text-primary mx-1">
          {"Add Vendor"}
        </span>
      </Divider>

      <div className="px-4 gap-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="">
              Vendor Name
            </label>
            <span className=" w-full">
              <InputText
                id="name"
                name="name"
                className="w-full text-lg p-primary-input"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.name && formik.errors?.name && (
            <div className="p-error">{formik.errors?.name}</div>
          )}
        </div>

        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="phone" className="">
              Vendor Phone Number
            </label>
            <span className=" w-full">
              <InputText
                id="phone"
                name="phone"
                className="w-full text-lg p-primary-input"
                value={formik.values?.phone}
                onChange={formik.handleChange}
              />
            </span>
          </div>
          {formik.touched?.phone && formik.errors?.phone && (
            <div className="p-error">{formik.errors?.phone}</div>
          )}
        </div>
        <div className="">
          <div className="flex flex-col gap-2">
            <label htmlFor="status" className="">
              Status
            </label>
            <SelectButton
              name="status"
              id="status"
              options={statusOptions}
              optionLabel="name"
              optionValue="value"
              className="flex"
              pt={{
                root: { className: "flex" },
                button: ({ context }) => ({
                  className: context.selected
                    ? "p-primary-highlight-btn w-full text-lg text-center"
                    : "w-full text-lg text-center",
                }),
              }}
              value={formik.values.status}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
