import React, { useEffect } from "react";
import { Button } from "primereact/button";
import {
  getCategory,
  getSubCategory,
} from "../../store/AsyncMethods/CategoryMethod";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RESET_ERROR, RESET_SUCCESS } from "../../store/Types/AuthTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { getProducts } from "../../store/AsyncMethods/ProductMethod";
import { getSales } from "../../store/AsyncMethods/SalesMethod";
import SalesTable from "./SalesTable";
import { RESET_SPECIFIC_SALES } from "../../store/Types/SalesTypes";

export default function SalesMain() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { success, error, user } = useSelector((state) => state.AuthReducer);

  useEffect(() => {
    if (user) {
      dispatch(getSubCategory());
      dispatch(getProducts());
      dispatch(getSales());
    }
  }, []);

  useEffect(() => {
    dispatch({ type: RESET_SPECIFIC_SALES });
  }, [location.pathname]);

  return (
    <div className="">
      <div className="flex justify-end">
        <Button
          label="Add Sales"
          icon="pi pi-plus"
          className="p-primary-btn"
          onClick={() =>
            navigate(user?.role_id == 1 ? "/add-sales" : "/add-sales-sales")
          }
        />
      </div>
      <div className="my-4">
        <SalesTable />
      </div>
    </div>
  );
}
